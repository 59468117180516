import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
  IoTimer,
  IoCheckmarkDoneCircle,
  IoOptions,
  IoCalendar,
  IoPersonRemove,
  IoCamera,
  IoTime,
  IoCreate,
} from "react-icons/io5";
import { useModal } from "../../components/useModal";

import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../../components/util";
import { Modal, ProductPricing, Feature } from "../../components/site";
import Image from "../../components/image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import DroppahLogo from "../../images/logos/Droppah/Droppah_Full_White.svg";
import FromFlexiTime from "../../images/logos/FlexiTime/FromFlexiTime_White.svg";

const FromFlexiTimeBadge = styled.img`
  z-index: 99;
  width: 130px;
`;

const RostersLP = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout fullPage showChat>
      <Seo
        title="Rosters, Time Tracking & Attendance Software | Droppah"
        description="Discover Droppah, staff rostering, time tracking & attendance software for your business. Simplify rostering & pay your staff right."
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container css={{ position: "sticky", top: "0", zIndex: "999" }}>
        <Wrapper stackGap={10}>
          <Row alignCenter noBreak>
            <img
              src={DroppahLogo}
              alt="Droppah | Rosters, Time Tracking & Attendance Software"
              css={{ maxWidth: "220px", maxHeight: "30px" }}
            />
            <FlexButtons className="hideOnMobile">
              <Button className="primary -md gtm-cta" to="/signup">
                Let's Do It!
              </Button>
            </FlexButtons>
            <div className="showOnMobile">
              <Button className="primary -md gtm-cta" to="/signup">
                Let's Do It!
              </Button>
            </div>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Box size={100} stackGap={40} centerOnMobile>
            <Box className="-textCenter" stackGap={5}>
              <h1 className="-fontLarge">
                Perfect rosters,
                <br className="hideOnMobile" />{" "}
                <span className="-fontPrimary">every time</span>
              </h1>
              <h3 className="-fontNormal">
                Powerful staff scheduling software with all the goods.
              </h3>
            </Box>
            <Box stackGap={20}>
              <FlexButtons justify="center">
                <Button className="primary -xl gtm-cta" to="/signup">
                  Let's Do It!
                </Button>
              </FlexButtons>
            </Box>
          </Box>
          <Image
            filename="DroppahHero_Cropped.png"
            alt="Droppah | Rosters, Time Tracking & Attendance Software"
            maxWidth={1000}
            centerImage
            addShadow
          />
        </Wrapper>
      </Container>
      <Container bg="deeppurple">
        <Wrapper>
          <Row stackGap={60} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoCalendar />
                <br />
                Build your ideal roster in a single click
              </h3>
              <p>
                Droppah’s AI auto-scheduling takes skills, availability, shift
                preferences, wage costs, and other requirements into account to
                automatically build highly optimised rosters in no time.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoCamera />
                <br />
                Accurately record hours worked
              </h3>
              <p>
                Save on your wage costs and increase timesheet accuracy by
                recording time and breaks with our photo time clock app.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoTimer />
                <br />
                Monitor attendance in real-time
              </h3>
              <p>
                Monitor the day’s attendance & breaks in real-time. Keep a tab
                on who’s in, who's on break, who’s late and who’s working
                overtime.
              </p>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={120}>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                alt="Optimise roster costs & manage shifts easily"
                filename="Droppah_CalendarAndDrag_Hospo.png"
                addShadow
                centerImage
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Fast employee scheduling
                </h2>
                <h4 className="h2">Roster the right staff at the right time</h4>
              </Box>
              <Feature
                icon={<IoCheckmarkDoneCircle key={0} />}
                title="Perfect rosters, every time"
                description="Schedule the right number of employees for each department to meet demand and keep your costs down. Add, edit and swap shifts quickly and easily."
              />
              <Feature
                icon={<IoOptions key={0} />}
                title="Control costs & optimise coverage"
                description="Accurately cost staff against sales to ensure you’ve got the right coverage at the right times. Set strict roster start times to eliminate unplanned overtime."
              />
              <Feature
                icon={<IoCreate key={0} />}
                title="Tailor to your business requirements"
                description="Easily schedule work across multiple locations, departments and roles. Set up rosters that match your business timetable - be it weekly or otherwise."
              />
            </Box>
          </Row>
          <Row stackGap={100} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Simple, accurate time tracking
                </h2>
                <h4 className="h2">
                  Record exact clock in and out times for fast, error-free
                  payroll
                </h4>
              </Box>
              <Feature
                icon={<IoCamera key={0} />}
                title="Start shifts with a selfie"
                description="Get your staff to clock in and out and record breaks with our photo time clock app. It's easy and fun for employees to use, increases your payroll accuracy and saves hours of admin time."
              />
              <Feature
                icon={<IoTime key={0} />}
                title="Automatic timesheets"
                description="Clock in and outs automatically record to-the-minute timesheets. No more collecting paper timesheets, timesheet rounding or manual data entry."
              />
              <Feature
                icon={<IoPersonRemove key={0} />}
                title="Staff unavailability"
                description="Reduce the admin of creating rosters by setting regular and one-off availability for your employees."
              />
            </Box>
            <Box size={50}>
              <Image
                alt="Photo time clock & real-time staff attendance"
                filename="Droppah_ClockAndLive_Hospo.png"
                addShadow
                centerImage
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2 className="h1">Pricing</h2>
            </div>
            <ProductPricing />
          </Box>
        </Wrapper>
      </Container>
      <Container className="primary">
        <Wrapper>
          <Box stackGap={30}>
            <Box stackGap={7} className="-textCenter">
              <h2>
                Create perfect rosters{" "}
                <span className="-fontDark">without all the admin</span>
              </h2>
              <h3 css={{ color: "#333" }} className="-fontNormal">
                Talk to the team to see how Droppah can help your business.
              </h3>
            </Box>
            <FlexButtons justify="center">
              <Button className="secondary -xl gtm-cta" to="/signup">
                Let's Do It!
              </Button>
              {/* <Button
                className="grey -lg gtm-cta"
                to="#!"
                onClick={toggleModal}
                atag
              >
                Book a Sales Call
              </Button> */}
            </FlexButtons>
          </Box>
          <FromFlexiTimeBadge
            className="-center"
            src={FromFlexiTime}
            alt="FlexiTime | Workforce Management for Modern Employers"
          />
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/droppah-sales/sales-call"
        embedTitle="Book a Sales Call | Droppah Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default RostersLP;
